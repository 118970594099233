<script setup>
import { useLayoutStore } from '~/stores/layout.store';

const props = defineProps({
  filterTeammate: {
    type: Function,
    required: true,
  }
});
const layoutStore = useLayoutStore();
const isOpen = ref(false);
const cities = ref([]);
const languages = ref([]);
const filters = ref({ cityId: null, langId: null });

const fetchHasOfficeCities = async () => {
  cities.value = await layoutStore.fetchHasOfficeCities()
};
const fetchLanguages = async () => {
  languages.value = await layoutStore.fetchLanguages()
};
const selectCityId = (cityId) => {
  filters.value.cityId = cityId;
};
const selectLangId = (langId) => {
  filters.value.langId = langId;
};
const submit = () => {
  isOpen.value = false;
  props.filterTeammate(filters.value);
};

onMounted(() => {
  fetchHasOfficeCities();
  fetchLanguages();
});
</script>

<template>
  <button @click="() => isOpen = true"><i class="fa-solid fa-filter fa-xl"></i></button>

  <w-slideover v-model="isOpen" :width="`full`">
    <div class="overflow-y-auto h-screen bg-gray-100">
      <div class="flex justify-justify items-center px-5 mt-3 text-gray-700">
        <div class="text-2xl">Filter</div>
        <div class="ms-auto" @click="isOpen = false"><i class="fa-solid fa-close fa-xl"></i></div>
      </div>
      <div class="w-full flex flex-col px-4 mt-4 mb-14">
        <div class="mt-6 space-y-3">
          <h3 class="mb-3 text-xl">{{ $t('client.cities') }}</h3>
          <div class="flex items-center gap-x-3 border-b pb-2" @click="selectCityId(null)">
            <input id="1" name="" type="radio" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              :checked="filters.cityId == null">
            <label for="1" class="block text-sm font-medium leading-6 text-gray-900">All</label>
          </div>
          <template v-for="city in cities">
            <div class="flex items-center gap-x-3 border-b pb-2" @click="selectCityId(city._id)">
              <input :id="city._id" name="city" type="radio"
                class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                :checked="city._id == filters.cityId">
              <label :for="city.id" class="block text-sm font-medium leading-6 text-gray-900">{{ city.name }}</label>
            </div>
          </template>
        </div>
        <div class="mt-6 space-y-3">
          <h3 class="mb-3 text-xl">{{ $t('client.cities') }}</h3>
          <div class="flex items-center gap-x-3 border-b pb-2" @click="selectLangId(null)">
            <input id="1" name="" type="radio" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              :checked="filters.langId == null">
            <label for="1" class="block text-sm font-medium leading-6 text-gray-900">All</label>
          </div>
          <template v-for="lang in languages">
            <div class="flex items-center gap-x-3 border-b pb-2" @click="selectLangId(lang._id)">
              <input :id="lang._id" name="lang" type="radio"
                class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                :checked="lang._id == filters.langId">
              <label :for="lang._id" class="block text-sm font-medium leading-6 text-gray-900">{{ lang.name }}</label>
            </div>
          </template>
        </div>
      </div>
      <div class="flex gap-3 fixed bottom-0 w-full p-3 bg-white">
        <button @click="isOpen = false" class="bg-indigo-200 text-indigo-900 rounded-lg p-3 capitalize">
          Close
        </button>
        <button @click="submit"
          class="w-full py-3 bg-indigo-900 rounded-lg justify-center items-center inline-flex  text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          {{ $t('client.search') }}
        </button>
      </div>
    </div>
  </w-slideover>
</template>

<style>
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

input[type="radio"]+label {
  position: relative;
  cursor: pointer;
  width: 100%;
  line-height: 20px;
}

input[type="radio"]+label::before {
  content: '';
  position: absolute;
  right: 0;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: inline-block;
}

input[type="radio"]:checked+label::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
  position: absolute;
  right: 0;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  color: #312e81;
  font-size: 25px;
  line-height: 1;
}
</style>